<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="trapoin"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="mb-4 pt-4 px-2">
            <v-col
              cols="12"
              sm="12"
              style="
              display: flex;
              justify-content: space-between;
            "
            >
              <div class="ml-4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cari"
                  color="purple"
                  single-line
                  hide-details
                  clearable
                  outlined
                  dense
                  @keyup.enter.native="filterData"
                  @click:append="filterData"
                  @click:clear="filterClear"
                />
              </div>

              <div v-if="wpointTopupCreate">
                <v-btn
                  class="text-capitalize"
                  style="
                  background: #5DB025;
                  border: none;
                  outline: none;
                "
                  @click="dialogTopUp = true"
                >
                  Top Up Trapoin
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            class="action-container"
            style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
            @click="toDetail(item)"
          >
            <v-icon
              class="action-icon"
              style="font-size: 16px"
            >
              fas fa-eye
            </v-icon>
          </div>
        </template>

        <template v-slot:item.nominal="{ item }">
          <span>
            {{ item.nominal | dotThousandSeparator }}
          </span>
        </template>

        <template v-slot:item.status_string="{ item }">
          <v-chip :color="'#' + item.status_color">
            <span style="color: #FFFFFF">
              {{ item.status_string }}
            </span>
          </v-chip>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <!-- <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span> -->
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </div>
          <!-- <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row> -->
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG TOPUP -->
    <v-dialog
      v-model="dialogTopUp"
      max-width="600"
    >
      <v-card class="px-6 py-6">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Top Up Trapoin
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogTopUp = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col
            class="font-common d-flex"
            cols="4"
            sm="4"
          >
            Tipe
          </v-col>
          <v-col
            class="font-common my-0 py-0"
            cols="8"
            sm="8"
          >
            <v-select
              v-model="selectedTipe"
              placeholder="Pilih Tipe"
              color="purple"
              :items="tipe"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common d-flex"
            cols="4"
            sm="4"
          >
            Nama Entitas
          </v-col>
          <v-col
            class="font-common my-0 py-0"
            cols="8"
            sm="8"
          >
            <v-combobox
              id="combobox-entitas"
              v-model="selectedEntitas"
              placeholder="Pilih Entitas"
              color="purple"
              :items="entitas"
              item-text="name"
              item-value="hash"
              outlined
              dense
              @keyup="entitasOnChange"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common d-flex"
            cols="4"
            sm="4"
          >
            Nominal Trapoin
          </v-col>
          <v-col
            class="font-common my-0 py-0"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="nominalTrapoin"
              outlined
              color="purple"
              placeholder="Masukkan Nominal Trapoin"
              auto-complete="off"
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common d-flex"
            cols="4"
            sm="4"
          >
            Keterangan
          </v-col>
          <v-col
            class="font-common my-0 py-0"
            cols="8"
            sm="8"
          >
            <v-textarea
              v-model="keterangan"
              color="purple"
              placeholder="Masukkan Keterangan"
              no-resize
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common d-flex"
            cols="4"
            sm="4"
          >
            Password
          </v-col>
          <v-col
            class="font-common my-0 py-0"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="password"
              type="password"
              outlined
              color="purple"
              placeholder="Masukkan Password"
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col class="text-right">
            <v-btn
              class="elevation-0"
              style="
                background: #7A1878;
                border: none;
                outline: none;
              "
              @click="topUp"
            >
              <span class="text-capitalize font-common-white-bold">
                Simpan
              </span>
            </v-btn>

            <v-btn
              class="elevation-0"
              style="
                background: #F2F2F2;
                border: none;
                outline: none;
              "
              @click="dialogTopUp = false"
            >
              <span
                class="text-capitalize font-common"
                style="color: #CCCCCC"
              >
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG DETAIL -->
    <v-dialog
      v-model="dialogDetail"
      max-width="600"
    >
      <v-card class="px-6 py-6">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Detail Top Up
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogDetail = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <template v-if="selectedItem !== null">
          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nama Entitas
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.target_name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Tipe
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.target_type }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nominal
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ (selectedItem.nominal || 0) | dotThousandSeparator }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Tanggal Request
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.request_time }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Keterangan
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.request_notes }}
            </v-col>
          </v-row>

          <template v-if="selectedItem.show_approval_section">
            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="wpointTopupMod">
              <v-col
                class="d-flex font-common"
                cols="4"
                sm="4"
                style="flex-direction: column"
              >
                <span>
                  Keterangan
                </span>

                <span>
                  *Wajib diisi jika menolak
                </span>
              </v-col>

              <v-col
                class="font-common-bold"
                cols="8"
                sm="8"
              >
                <v-textarea
                  v-model="keteranganApproval"
                  color="purple"
                  placeholder="Masukkan Keterangan"
                  no-resize
                  outlined
                />
              </v-col>
            </v-row>

            <v-row v-if="wpointTopupMod">
              <v-col class="text-right">
                <v-btn
                  style="
                    background: #7A1878;
                    border: none;
                    outline: none;
                  "
                  @click="approval(1)"
                >
                  <span class="font-common-white-bold text-capitalize">
                    Verifikasi
                  </span>
                </v-btn>

                <v-btn
                  style="
                    background: #EA3E3A;
                    border: none;
                    outline: none;
                  "
                  @click="approval(0)"
                >
                  <span class="font-common-white-bold text-capitalize">
                    Tolak
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-common-black-bold">
                Approval
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="4"
                sm="4"
              >
                Status
              </v-col>

              <v-col
                class="font-common-bold"
                cols="8"
                sm="8"
              >
                {{ selectedItem.status_string }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="4"
                sm="4"
              >
                Tanggal
              </v-col>

              <v-col
                class="font-common-bold"
                cols="8"
                sm="8"
              >
                {{ selectedItem.action_time || '-' }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="4"
                sm="4"
              >
                Keterangan
              </v-col>

              <v-col
                class="font-common-bold"
                cols="8"
                sm="8"
              >
                {{ selectedItem.action_note || '-' }}
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dialogTopUp: false,
      dialogDetail: false,

      // Trapoin:
      search: '',
      headers: [
        { text: 'Nama Entitas', value: 'target_name', sortable: false },
        { text: 'Tipe', value: 'target_type' },
        { text: 'Nominal', value: 'nominal' },
        { text: 'Tanggal Request', value: 'request_time' },
        { text: 'Pembuat', value: 'request_by' },
        { text: 'Status', value: 'status_string' },
        { text: '', value: 'actions' },
      ],
      trapoin: [],

      start_date: '',
      end_date: '',

      recap: {
        aktif: 0,
        terpakai: 0,
      },

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      selectedTipe: null,
      tipe: [
        {
          text: 'Personal',
          value: 1,
        },
        {
          text: 'Perusahaan',
          value: 2,
        },
      ],

      selectedEntitas: null,
      entitas: [],

      nominalTrapoin: '',
      keterangan: '',
      password: '',

      selectedItem: null,

      keteranganApproval: '',

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      wpointTopupCreate () {
        let v = false
        if (this.userMenus.includes('WPOINT_TOPUP_CREATE')) v = true

        return v
      },

      wpointTopupMod () {
        let v = false
        if (this.userMenus.includes('WPOINT_TOPUP_MOD')) v = true

        return v
      },

    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      // this.getPerusahaan(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/wpoint/topup', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.trapoin = res.data.data.list

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      entitasOnChange () {
        const keyword = document.getElementById('combobox-entitas').value

        if (keyword.length < 3) return

        const vm = this

        const requestBody = {
          target_type: this.selectedTipe,
          target_keyword: keyword,
        }

        axios.post('/v2/t/wpoint/topup/searchUser', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.entitas = res.data.data.all_name
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      topUp () {
        const requestBody = {
          target_type: this.selectedTipe,
          target_id: this.selectedEntitas.hash,
          nominal: this.nominalTrapoin,
          notes: this.keterangan,
          pwd: this.password,
        }

        axios.post('/v2/t/wpoint/topup/create', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialogTopUp = false
            this.$toast.success('Berhasil menyimpan topup trapoin')
            this.selectedTipe = null
            this.selectedEntitas = null
            this.keterangan = ''
            this.password = ''
            this.nominalTrapoin = ''
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      approval (status) {
        const requestBody = {
          topup_hash: this.selectedItem.hash,
          new_status: status,
          notes: this.keteranganApproval,
        }

        axios.post('/v2/t/wpoint/topup/mod', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialogDetail = false
            this.$toast.success('Berhasil')
            this.keteranganApproval = ''
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        this.selectedItem = item
        this.dialogDetail = true
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

#action-container {
  background: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
